<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Yard management">
      <template #module-nav-logo>
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <div class="d-flex flex-1 flex-column px-15 py-8">
      <v-dialog
        key="scan-qr-code"
        v-model="displayScanElementDialog"
        max-width="500px"
        @click:outside="displayScanElementDialog = false"
        @keydown.esc="displayScanElementDialog = false"
      >
        <v-card>
          <v-card-title>Scan Element id</v-card-title>
          <v-card-text>
            <base-ant-scanner @decodedResult="validateElement" />
          </v-card-text>
          <div class="d-flex px-5 py-2 align-center">
            <v-text-field
              v-model="qrCodeScannerManualInput"
              placeholder="element"
              single-line
              filled
              dense
              hide-details
            />
            <v-divider vertical class="mx-5" />
            <v-btn
              elevation="0"
              color="primary"
              @click="validateElement(qrCodeScannerManualInput)"
              >submit
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <store-element-dialog
        :displayed="displayStoreElementDialog"
        @closeDialog="displayStoreElementDialog = false"
      />
      <TransitionGroup name="move-top" tag="div">
        <yard-management-item
          v-for="group in taskGroups"
          :key="group[0].id"
          :tasks="group"
          :halls="dhmeYardManagementAssemblyHalls"
          :locations="dhmeYardManagementStorageLocations"
          class="mb-2 ant-glass-background pick-order-item"
          :class="{
            'my-pick-order': isTaskMine(group[0]),
            'other-pick-order': isTaskPickedUpNotByMe(group[0]),
          }"
          @storeElement="displayStoreElementDialog = true"
          @validateElement="displayScanElementDialog = true"
        >
        </yard-management-item>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import { DHME_M_YARD_MANAGEMENT } from '@/modules/modules';
import moment from 'moment';
import BaseAntScanner from '@/components/Base/BaseAntScanner.vue';
import StoreElementDialog from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/StoreElementDialog.vue';
import YardManagementItem from '@/components/Modules/Daiwa-House-Modular-Europe/PickOrderAndYardManagement/YardManagementItem.vue';

export default {
  name: 'DHMEMYardManagement',
  components: {
    YardManagementItem,
    StoreElementDialog,
    BaseAntScanner,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      displayScanElementDialog: false,
      displayStoreElementDialog: false,
      qrCodeScannerManualInput: '',
    };
  },
  computed: {
    moment() {
      return moment;
    },
    ...mapGetters([
      'project',
      'dhmeYardManagementTasks',
      'dhmeYardManagementAssemblyHalls',
      'dhmeYardManagementStorageLocations',
      'dhmeYardManagementSelectedTask',
      'dhmeYardManagementSelectedGroup',
      'authenticatedUser',
    ]),
    taskGroups() {
      let tasks = [...this.dhmeYardManagementTasks];

      let groups = [];
      let usedTaskIds = [];

      tasks.forEach((task) => {
        if (!usedTaskIds.some((y) => y === task.id)) {
          let taskGroup = tasks.filter(
            (item) =>
              item.due === task.due &&
              item.task_type.custom_1 === task.task_type.custom_1 &&
              item.task_type.custom_3 === task.task_type.custom_3 &&
              item.task_type.custom_4 === task.task_type.custom_4
          );

          groups.push(taskGroup);
          usedTaskIds = [...usedTaskIds, ...taskGroup.map((x) => x.id)];
        }
      });

      if (this.dhmeYardManagementSelectedGroup.length > 0) {
        let tmp = groups.filter(
          (group) =>
            !group.some((task) =>
              this.dhmeYardManagementSelectedGroup.some(
                (selectedTask) => selectedTask.id === task.id
              )
            )
        );
        tmp.unshift(this.dhmeYardManagementSelectedGroup);
        return tmp;
      }
      return groups;
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_YARD_MANAGEMENT
      ).id;
    },
  },
  mounted() {
    this.$store.dispatch('fetchDhmeYardManagementData', {
      moduleId: this.moduleId,
      sessionId: this.$route.params.sessionId ?? null,
    });

    this.$store.dispatch('fetchDhmeYardTasks', {
      moduleId: this.moduleId,
    });
  },
  methods: {
    async validateElement(scannedResult) {
      try {
        await this.$store.dispatch(
          'validateElementDhmeYardManagementTask',
          scannedResult
        );
        this.displayScanElementDialog = false;
        this.qrCodeScannerManualInput = '';
      } catch (e) {}
    },
    isTaskMine(task) {
      return (
        task.task_type.custom_5 &&
        task.assigned_to.id === this.authenticatedUser.id
      );
    },
    isTaskPickedUpNotByMe(task) {
      return (
        task.task_type.custom_5 &&
        task.assigned_to.id !== this.authenticatedUser.id
      );
    },
  },
};
</script>

<style scoped lang="scss">
.my-pick-order {
  border-left: solid 4px var(--v-primary-base);
}

.other-pick-order {
  opacity: 0.6;
  pointer-events: none;
}

.pick-order-item {
  transition: 400ms ease-out;
  position: relative;
  z-index: 1;
}

.move-top-move {
  transition: transform 400ms ease-out;
}
</style>
